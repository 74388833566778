<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <contact-us-content-modal ref="detailsModal" :selectedData="selectedData" />
    <MyDataTable
      title="مدیریت ارتباط با ما"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      :isEditEnabled="false"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <template v-if="row.user">
          <td>{{ row.user.first_name + " " + row.user.last_name }}</td>
          <td>{{ row.user.cellphone || "-" }}</td>
          <td>{{ row.user.email || "-" }}</td>
        </template>
        <template v-else>
          <td>{{ row.name }}</td>
          <td>{{ row.cellphone || "-" }}</td>
          <td>{{ row.email || "-" }}</td>
        </template>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import ContactUsContentModal from "../../../../components/Panel/ContactUsContentModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    ContactUsContentModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/contacts/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: "user",
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/categories/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/contacts/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/contacts/index",
      pathParams: {},
      headers: [
        {
          text: "نام",
          align: "start",
          value: "name",
        },
        { text: "شماره تلفن", value: "cellphone" },
        { text: "ایمیل", value: "email" },
        { text: "عملیات", value: "desc" },
      ],
    };
  },
};
</script>

<style></style>
